import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} /> 
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
       <path d="M3395 7890 c-27 -6 -77 -15 -110 -21 -33 -5 -76 -14 -95 -19 -19 -5
-57 -14 -85 -20 -136 -32 -373 -123 -549 -212 -106 -54 -272 -156 -308 -191
-7 -7 -53 -44 -103 -84 -252 -200 -479 -502 -600 -793 -133 -323 -184 -596
-185 -982 0 -218 7 -289 47 -453 41 -173 149 -420 249 -570 51 -77 166 -216
194 -234 14 -10 21 -11 16 -3 -12 20 3 14 42 -18 63 -50 107 -63 161 -49 41
10 51 10 72 -4 43 -29 138 -57 170 -50 33 6 39 23 8 23 -30 0 -129 51 -129 67
0 8 16 30 35 50 76 79 80 214 8 294 -22 24 -50 43 -77 50 -51 15 -104 15 -119
0 -6 -6 -19 -11 -29 -11 -10 0 -18 -3 -18 -7 0 -5 21 -7 47 -5 67 5 121 -16
156 -60 27 -33 31 -48 35 -116 4 -69 1 -84 -22 -129 -30 -61 -69 -79 -149 -70
-60 8 -109 39 -136 88 -26 46 -29 158 -6 214 l16 37 -25 -19 c-14 -10 -26 -21
-26 -25 0 -5 -5 -8 -10 -8 -7 0 -7 6 -1 18 5 9 16 33 25 52 23 53 98 132 149
158 26 13 49 28 53 33 3 5 -14 44 -38 87 -180 315 -247 662 -218 1132 28 459
114 770 287 1034 64 98 220 251 313 305 41 25 82 50 90 56 8 6 42 21 75 34 33
12 74 28 90 36 41 18 181 50 285 66 113 17 475 18 563 2 37 -7 97 -18 135 -24
114 -19 345 -102 441 -158 28 -17 54 -31 57 -31 7 0 127 -83 158 -110 192
-166 299 -306 405 -531 83 -178 134 -374 160 -620 6 -64 34 -116 86 -159 9 -7
21 -22 27 -33 10 -19 8 -20 -42 -13 -68 9 -108 30 -116 61 -3 14 -10 25 -15
25 -9 0 -95 -60 -287 -201 -173 -126 -217 -184 -171 -222 27 -22 -4 -22 779 4
179 6 407 9 508 7 l184 -3 -2 -330 c0 -181 2 -345 6 -362 l7 -33 242 0 243 0
7 281 c4 155 10 324 14 375 l6 94 240 5 c227 5 631 27 776 41 55 5 68 4 83
-11 17 -17 17 -29 1 -67 -11 -26 14 -23 66 10 24 15 130 77 234 136 194 111
235 143 235 181 0 55 -87 79 -241 65 -57 -5 -201 -9 -319 -10 -118 0 -357 -4
-530 -10 -173 -5 -529 -14 -790 -20 -261 -5 -609 -13 -772 -17 l-298 -6 0 49
c0 50 -26 134 -41 134 -5 0 -9 7 -9 15 0 13 26 15 168 16 92 0 185 3 207 7
l40 8 -2 79 c-6 186 -61 440 -135 625 -157 389 -402 664 -762 858 -190 103
-313 145 -566 193 -119 23 -477 35 -565 19z"/>
<path d="M5090 5348 c-215 -8 -215 -7 -225 -77 -11 -76 -49 -220 -81 -311 -19
-52 -34 -101 -34 -107 0 -10 43 -13 183 -13 l184 0 51 93 c83 152 179 385 168
412 -2 7 -15 12 -28 12 -13 -1 -111 -5 -218 -9z"/>
<path d="M8856 4675 c-14 -13 -26 -28 -26 -33 0 -15 -100 -193 -137 -244 -38
-52 -48 -57 -56 -30 -13 39 -164 316 -174 319 -24 8 -34 -42 -43 -200 -5 -89
-13 -181 -18 -205 -9 -40 -8 -43 11 -40 18 3 22 11 23 53 8 176 17 246 31 243
8 -2 44 -59 79 -128 93 -181 81 -166 107 -136 12 14 31 47 41 73 22 54 125
213 137 213 14 0 18 -38 21 -180 3 -148 5 -152 64 -130 26 10 27 13 13 24 -14
10 -18 44 -24 219 -4 114 -11 207 -15 207 -4 0 -19 -11 -34 -25z"/>
<path d="M2430 4681 c-13 -8 -14 -36 -10 -189 6 -208 12 -226 92 -251 41 -12
49 -12 99 7 l54 21 -56 0 c-71 1 -119 30 -132 81 -5 19 -7 95 -4 169 5 112 8
136 22 144 14 8 15 11 4 18 -18 11 -50 11 -69 0z"/>
<path d="M2721 4679 c-18 -7 -21 -12 -12 -22 7 -9 12 -89 13 -218 l3 -204 25
-3 c14 -1 31 2 39 7 13 8 13 12 1 26 -11 13 -13 56 -11 203 1 103 7 192 12
198 18 22 -28 30 -70 13z"/>
<path d="M2975 4682 c-6 -4 -10 -93 -10 -230 l0 -222 75 0 c77 0 117 11 85 24
-9 3 -38 6 -64 6 l-48 0 5 98 c3 54 10 100 16 104 6 4 34 8 63 8 78 0 75 19
-4 22 l-68 3 0 80 0 80 65 3 c150 5 141 32 -10 32 -52 0 -99 -4 -105 -8z"/>
<path d="M3345 4684 c-6 -2 -9 -86 -8 -226 l2 -223 53 -3 c29 -2 69 0 88 4 30
5 32 8 15 14 -11 5 -40 9 -65 9 l-45 1 3 92 c2 51 8 98 13 104 5 6 32 11 61
13 79 3 81 15 3 21 l-70 5 -3 60 c-5 104 -4 106 84 104 77 -2 91 2 79 20 -6
10 -187 15 -210 5z"/>
<path d="M5874 4676 c-28 -21 -13 -28 63 -30 l68 -1 0 -186 c0 -133 -4 -190
-12 -201 -16 -19 -7 -28 28 -28 30 0 47 16 32 31 -5 5 -5 43 -1 86 3 43 7 126
7 184 1 121 0 119 81 119 27 0 61 5 77 11 52 20 2 29 -163 29 -123 0 -165 -3
-180 -14z"/>
<path d="M6362 4681 c-11 -6 -13 -56 -13 -227 l0 -219 63 -3 c75 -4 119 5 97
19 -7 5 -36 9 -62 9 l-48 0 2 83 c3 120 7 127 73 127 30 0 58 5 61 10 4 6 -16
10 -52 10 -32 0 -63 4 -69 8 -14 9 -18 116 -4 141 9 17 22 21 88 23 43 2 77 7
77 13 0 13 -195 18 -213 6z"/>
<path d="M6732 4681 c-10 -6 -13 -58 -15 -212 -1 -112 -4 -211 -7 -219 -4 -12
10 -15 86 -18 79 -2 94 0 115 17 l24 20 -85 1 -85 0 3 158 c2 86 7 178 11 204
6 37 5 48 -6 52 -19 8 -25 7 -41 -3z"/>
<path d="M7073 4684 c-10 -4 -13 -60 -13 -230 l0 -224 73 0 c75 0 114 11 82
24 -9 3 -36 6 -61 6 l-43 0 6 98 c3 53 11 100 17 104 6 4 35 8 64 8 73 0 65
18 -10 22 l-63 3 -3 77 c-3 88 -6 86 101 90 31 2 58 8 60 13 2 10 -187 18
-210 9z"/>
<path d="M7653 4683 c-13 -2 -23 -9 -23 -14 0 -5 10 -9 23 -9 12 -1 33 -7 46
-14 28 -15 61 -10 61 10 0 7 -3 15 -7 17 -14 8 -78 14 -100 10z"/>
<path d="M8046 4674 c-14 -7 -33 -14 -43 -17 -10 -3 4 -5 30 -6 158 -4 243
-157 168 -301 -26 -49 -13 -52 28 -5 76 86 62 241 -26 299 -51 34 -124 48
-157 30z"/>
<path d="M3710 4661 c-5 -11 -10 -84 -11 -163 0 -79 -4 -169 -8 -200 -6 -51
-5 -58 10 -58 15 0 18 17 24 135 4 74 11 138 16 141 5 3 23 -12 41 -32 18 -21
55 -58 82 -83 27 -25 70 -71 96 -102 l47 -56 16 31 c12 23 18 73 22 186 3 85
8 170 11 188 4 28 2 32 -18 32 l-23 0 4 -155 c2 -85 0 -163 -3 -172 -6 -13
-26 2 -103 78 -97 95 -173 197 -173 231 0 24 -17 23 -30 -1z"/>
<path d="M4680 4654 c-6 -14 -10 -91 -11 -172 0 -81 -4 -168 -8 -194 -7 -45
-6 -48 13 -48 20 0 21 6 25 133 1 72 6 137 10 143 3 7 59 -44 136 -127 72 -76
133 -138 136 -139 20 -1 32 71 36 225 3 94 10 178 15 188 8 16 6 18 -18 15
l-28 -3 3 -161 c1 -89 -2 -164 -6 -167 -5 -3 -53 38 -105 91 -93 94 -168 195
-168 227 0 24 -20 18 -30 -11z"/>
<path d="M5200 4661 c-5 -11 -10 -84 -11 -163 0 -79 -4 -169 -8 -200 -6 -50
-5 -58 10 -58 18 0 29 70 29 191 0 37 3 74 6 82 5 13 128 -105 236 -227 l34
-38 16 24 c13 19 18 62 23 184 3 87 8 174 11 192 4 28 2 32 -18 32 l-23 0 4
-155 c2 -85 0 -163 -3 -172 -15 -37 -276 254 -276 308 0 25 -16 24 -30 0z"/>
<path d="M4347 4642 c-15 -15 -27 -33 -27 -40 0 -6 -7 -26 -15 -44 -8 -18 -38
-89 -66 -158 -28 -69 -58 -132 -66 -141 -11 -14 -12 -18 -1 -21 26 -9 46 3 40
24 -3 12 7 42 24 73 l28 53 65 7 c74 8 87 -1 98 -67 3 -21 10 -41 14 -44 5 -3
9 -14 9 -24 0 -25 15 -33 46 -26 24 6 33 31 13 38 -6 2 -23 48 -39 103 -57
200 -82 280 -89 288 -4 4 -19 -6 -34 -21z m26 -135 c24 -76 24 -77 -33 -77
-27 0 -50 3 -50 7 0 13 33 97 43 111 16 20 21 14 40 -41z"/>
<path d="M7452 4587 c-81 -84 -81 -213 2 -292 52 -51 139 -72 202 -50 62 21
54 35 -21 35 -78 0 -113 15 -152 67 -23 30 -28 48 -31 109 -4 64 -1 79 21 119
30 53 23 58 -21 12z"/>
<path d="M7898 4583 c-22 -25 -34 -51 -46 -103 -24 -97 30 -199 124 -235 40
-15 48 -15 99 0 65 19 64 35 -4 35 -62 0 -130 42 -156 96 -23 48 -24 134 -2
187 17 39 10 48 -15 20z"/>
<path d="M2160 4052 c0 -9 162 -122 175 -122 2 0 46 -20 97 -44 134 -63 256
-102 423 -137 77 -16 397 -16 495 0 125 19 253 49 426 97 l60 17 160 -83 c87
-45 182 -95 209 -110 28 -15 115 -60 195 -99 80 -40 157 -79 172 -87 54 -29
287 -127 418 -175 74 -28 146 -54 160 -59 91 -34 211 -54 330 -54 69 0 133 3
143 6 15 7 17 23 17 147 0 77 -3 146 -6 155 -3 9 -18 16 -32 16 -48 0 -237 72
-408 156 -175 86 -342 181 -495 284 -47 32 -99 65 -116 73 -28 15 -150 16
-1195 17 -641 0 -1179 3 -1196 6 -20 4 -32 2 -32 -4z"/>
<path d="M5856 4013 c-3 -21 -6 -319 -6 -663 0 -698 -6 -788 -63 -985 -37
-128 -119 -266 -211 -354 -20 -19 -36 -38 -36 -43 0 -4 57 -6 128 -5 70 1 213
-2 317 -7 315 -15 370 3 674 224 57 41 116 85 130 98 l26 22 -25 -4 c-48 -9
-146 -25 -225 -38 -84 -14 -448 -18 -460 -6 -4 4 10 29 32 55 77 92 143 266
161 423 11 90 26 760 27 1140 l0 175 -231 3 -231 2 -7 -37z"/>
<path d="M3500 1624 c-102 -27 -180 -74 -271 -164 -123 -121 -172 -229 -148
-324 21 -86 74 -114 192 -103 68 6 126 40 164 96 23 34 31 91 13 91 -5 0 -10
-14 -10 -31 0 -43 -78 -122 -135 -138 -69 -19 -130 -14 -162 13 -25 22 -28 30
-27 85 2 97 46 175 163 291 74 72 114 104 171 132 132 65 237 62 260 -8 25
-75 -17 -179 -167 -409 -163 -251 -170 -268 -107 -263 38 3 49 14 58 58 8 37
91 142 198 250 106 108 176 169 265 229 60 41 160 84 175 75 4 -2 -36 -68 -88
-146 -121 -179 -184 -307 -191 -386 -10 -104 28 -127 127 -77 82 42 78 61 -5
25 -77 -33 -90 -24 -74 51 24 108 146 305 288 466 45 51 81 100 81 110 0 26
-19 43 -50 43 -67 0 -292 -137 -467 -284 -57 -47 -103 -83 -103 -79 0 3 13 27
28 52 41 67 79 162 87 214 16 107 -22 147 -140 146 -38 0 -95 -7 -125 -15z"/>
<path d="M7155 1550 c-34 -13 -92 -85 -130 -159 l-35 -70 -62 -3 c-48 -2 -63
-7 -63 -18 0 -10 16 -18 48 -23 26 -5 47 -13 47 -19 0 -17 -72 -183 -122 -281
-28 -54 -67 -115 -87 -137 -72 -77 -157 -87 -184 -20 -3 9 -6 4 -6 -12 -1 -30
39 -68 72 -68 36 0 106 41 142 83 43 49 123 186 200 340 55 110 59 115 94 121
48 8 151 -13 151 -30 0 -17 -58 -152 -118 -274 -56 -114 -118 -182 -181 -201
l-44 -13 -28 34 c-24 28 -29 31 -29 14 0 -61 71 -89 138 -55 76 38 140 133
279 407 l57 111 78 14 c44 8 78 11 78 5 0 -5 -12 -30 -28 -55 -48 -81 -75
-143 -80 -186 -4 -37 -1 -44 23 -63 15 -12 41 -22 57 -22 34 0 93 29 130 64
15 14 30 26 33 26 3 0 5 -18 5 -40 0 -31 5 -43 24 -55 46 -30 112 0 213 98
l73 70 -23 -61 c-17 -48 -19 -66 -11 -79 19 -30 36 -21 98 57 64 78 197 213
204 206 2 -3 -14 -36 -37 -74 -97 -167 -60 -271 64 -176 26 19 48 34 48 32 38
-78 69 -97 135 -79 25 6 68 27 96 46 61 41 163 142 153 152 -4 3 -25 -11 -46
-33 -91 -95 -168 -144 -226 -144 -28 0 -37 5 -45 24 -5 13 -5 37 -1 52 6 23
15 30 43 35 97 15 218 106 218 164 0 120 -178 38 -287 -132 -18 -29 -54 -68
-80 -88 -81 -62 -104 -32 -55 68 17 34 39 71 49 82 43 48 83 104 83 118 0 25
-31 28 -74 7 -23 -11 -90 -65 -149 -121 -59 -56 -110 -99 -113 -96 -7 7 20 59
67 129 43 62 49 88 21 88 -11 0 -63 -53 -132 -134 -119 -140 -195 -201 -242
-194 -52 7 -29 72 71 202 34 43 61 82 61 87 0 4 -10 11 -21 14 -31 10 -67 -21
-116 -101 -48 -80 -112 -142 -177 -174 -61 -29 -86 -21 -86 29 0 46 44 121
182 312 54 75 98 141 98 146 0 16 -29 24 -49 13 -26 -13 -81 -77 -120 -137
l-33 -53 -74 0 c-41 0 -74 2 -74 5 0 21 65 127 90 149 36 30 49 32 76 11 28
-21 34 -18 34 16 0 25 -6 32 -35 45 -67 28 -120 -14 -196 -152 l-41 -74 -94 0
c-60 0 -94 4 -94 11 0 15 49 99 74 128 31 35 64 45 91 27 26 -17 35 -12 35 21
0 18 -9 28 -35 39 -39 16 -42 16 -70 4z m1395 -283 c0 -26 -58 -94 -103 -121
-50 -29 -107 -44 -107 -29 0 16 80 103 124 135 41 30 86 37 86 15z"/>
<path d="M7812 1398 c-19 -19 -14 -37 13 -50 21 -10 29 -9 43 5 13 13 14 21 6
35 -14 21 -45 27 -62 10z"/>
<path d="M4640 1394 c0 -3 11 -15 25 -26 72 -56 -17 -202 -193 -315 -56 -36
-77 -36 -62 0 5 12 30 73 56 136 46 110 46 114 28 128 -17 12 -21 11 -40 -12
-11 -14 -33 -62 -49 -106 -23 -63 -39 -90 -75 -123 -62 -58 -133 -98 -176 -99
-39 -1 -54 13 -54 55 0 45 10 58 44 58 46 0 148 54 190 100 39 43 46 79 20
104 -38 39 -129 1 -211 -88 -89 -96 -117 -183 -72 -225 48 -45 113 -34 215 35
l66 45 -6 -29 c-9 -37 21 -67 56 -57 78 25 269 197 307 278 27 56 22 108 -12
132 -21 15 -57 21 -57 9z m-300 -130 c0 -41 -50 -93 -125 -129 -92 -45 -101
-24 -23 55 78 80 148 115 148 74z"/>
<path d="M5126 1378 c-9 -12 -16 -30 -16 -39 0 -23 -67 -124 -137 -205 -94
-108 -198 -172 -251 -153 -29 11 -36 30 -28 74 6 31 10 35 39 35 81 0 227 108
227 167 0 98 -140 55 -246 -75 -79 -96 -94 -160 -49 -202 76 -71 237 17 376
205 35 47 66 85 69 85 3 0 16 -9 29 -19 l23 -18 -30 -59 c-59 -116 -41 -214
39 -214 48 0 133 46 193 106 76 74 71 89 -6 20 -100 -91 -193 -123 -219 -75
-18 35 5 90 77 185 48 63 63 89 55 97 -8 8 -20 8 -44 -2 -85 -34 -106 -19 -62
48 15 22 24 45 20 50 -11 18 -43 12 -59 -11z m-196 -120 c-1 -33 -52 -85 -117
-119 -86 -45 -105 -37 -56 23 81 98 173 149 173 96z"/>
<path d="M6195 1336 c-35 -15 -117 -102 -164 -172 -44 -67 -57 -115 -41 -153
14 -36 38 -45 88 -36 47 9 144 109 180 187 18 38 37 62 57 72 l30 15 -30 0
c-29 1 -31 4 -31 36 0 54 -36 74 -89 51z m34 -106 l22 -31 -24 -42 c-37 -62
-102 -133 -136 -147 -63 -25 -78 9 -38 88 24 50 122 162 141 162 7 0 22 -14
35 -30z"/>
<path d="M6705 1327 c-47 -26 -141 -135 -174 -201 -57 -116 5 -197 105 -136
60 37 164 172 164 212 0 8 15 20 33 28 l32 13 -31 4 c-27 4 -30 7 -26 33 3 19
-3 35 -17 49 -26 26 -36 26 -86 -2z m42 -89 c30 -28 29 -44 -3 -93 -57 -86
-138 -153 -170 -141 -24 9 -16 68 15 118 32 49 112 138 126 138 5 0 19 -10 32
-22z"/>
<path d="M5810 1317 c-55 -27 -179 -149 -203 -199 -24 -52 -22 -94 8 -123 13
-14 35 -25 48 -25 27 0 85 40 136 94 69 72 60 40 -30 -110 -55 -92 -108 -149
-157 -171 -43 -18 -66 -10 -86 30 l-16 32 0 -28 c0 -33 40 -77 70 -77 58 0
130 52 195 140 51 69 225 380 225 401 0 12 -7 19 -20 19 -10 0 -24 9 -30 20
-15 28 -82 26 -140 -3z m127 -18 c20 -20 -147 -234 -220 -282 -67 -45 -103
-11 -69 65 32 70 173 198 241 218 36 11 36 11 48 -1z"/>
<path d="M2447 1033 c-4 -3 -7 -17 -7 -29 0 -31 44 -44 68 -21 14 15 14 18 -3
37 -18 20 -45 26 -58 13z"/>
<path d="M2692 1028 c-28 -28 1 -64 43 -54 30 8 34 41 6 56 -25 13 -34 13 -49
-2z"/>
<path d="M2937 1033 c-13 -12 -7 -51 9 -57 27 -10 43 -7 54 13 7 15 6 23 -6
35 -16 16 -46 21 -57 9z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
